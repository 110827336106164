import React from "react"
import SEO from "../components/seo"
import Header from "../components/headers/secondary/Secondary";
import Navigator from "../components/navigator/DefaultNavbar";

const IndexPage = () => (
  <>
    <SEO
      title="404"
      description="Create your backend infrastructure up to 80% faster. Automatically create the project boilerplate in Node.js with TypeScript and deploy it to AWS within a few minutes." />
    <Navigator
      useSpaNav={true}
    />
    <Header
      big={true}
      title="404 - Not found"
    />
  </>
)

export default IndexPage
